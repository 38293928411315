import React from 'react'
import Layout from "../layouts/de"
import Block from "../components/Block"

const InfoPage = (props) => (
  <Layout 
    location={props.location} 
    title="Info - Digitale Kreativagentur Kitzingen"
    description="Info & Kontakt : Web-Entwickler und Designer: Cristian López - mail@lopezdesign.de - Kitzingen Deutschland"
    >
    <Block color="white"
      left={
        <div className="grid__item width-desk--1of2 space--bottom">
          <h1 className="shine">Info</h1>
          <h4>mail@lopezdesign.de</h4>
          <h4>lopezdesign.de</h4>
          <h4><a target="_blank" rel="noreferrer" title="instagram" href="https://www.instagram.com/cris_kreativ/">Instagram</a></h4>
          <h4><a target="_blank" rel="noreferrer" title="unsplash" href="https://unsplash.com/@cris_kreativ">Unsplash</a></h4>
          <a className="btn" title="mail@lopezdesign.de" href="mailto:mail@lopezdesign.de">E-Mail</a>
        </div>
      }
    />
    <Block color="colorCoral"
      left={
        <div className="grid__item width-desk--1of2 space--bottom">
          <h2 className="text--big"><span>Über</span><span>mich</span></h2>
        </div>
      }
      right={
        <div className="grid__item width-desk--1of2">
          <p>Hi, ich bin Cristian López. Ich bin Webdesigner und Entwickler mit über 3 Jahren Erfahrung mit CMS Contao, 
            UI Designs, Online Shop. Angefangen habe ich in einer Werbeagentur in Würzburg im Bereich Webentwicklung, 
            außerdem habe ich eine Ausbildung als Mediengestalter gemacht.
          </p>
        </div>
      }
    />
  </Layout>
)

export default InfoPage
